import React from "react"
import Layout from "../components/Layout/Layout"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import { venuesURL } from "../common/utils/urls"

const NotFoundPage = () => {
  return (
    <div>
      <Container className="not-found-container">
        <h2 className="title">
          We're sorry, the page you were looking for cannot be found.
        </h2>
        <p className="description">
          This may be because it has been removed, we've changed its name or it
          is temporarily unavailable.
        </p>
        <div className="cta-section">
          <Link to={venuesURL} className="button">
            Venues
          </Link>
          <Link to={"/"} className="button button-black">
            Homepage
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default NotFoundPage
